import React, {useState, Dispatch, SetStateAction} from "react";
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import SavingsTwoToneIcon from '@mui/icons-material/SavingsTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {IScoreProps} from "../../types/props";




const Score: React.FC<IScoreProps> = ({score, setScore}) => {
    const scoreString = score.toString().padStart(7,'0')
    return (
        <>
            <Box>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                        <Stack direction="row" alignItems="center" gap={2}>
                            <MonetizationOnTwoToneIcon
                                fontSize="large"
                                sx={{ fontSize: 40, color: '#ffd700' }}
                            />
                            <Typography component="div">
                                <Box sx={{ fontSize: 'h3.fontSize', m: 1 }}>{scoreString}</Box>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Score;
