import React, {useEffect, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import WebApp from '@twa-dev/sdk'
import { AppRoot, List, Button, Placeholder } from '@telegram-apps/telegram-ui';
import {CellSection} from "../../components/CellSection";
import {FormSection} from "../../components/FormSection/FormSection";
import {BannerSection} from "../../components/BannerSection";
import {TimelineSection} from "../../components/TimelineSection/TimelineSection";
import {TooltipSection} from "../../components/TooltipSection/TooltipSection";
import {ModalSection} from "../../components/ModalSection/ModalSection";
import Grid from '@mui/material/Grid';
import '@telegram-apps/telegram-ui/dist/styles.css';
import Clicker from "../../components/Clicker/Clicker";
import Score from "../../components/Score/Score";
import {useCookies} from "react-cookie";

interface UserData {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  language_code: string;
  is_premium?: boolean;
}
const check_undefined = (data: number | undefined) : number => {
    if (data === undefined){
        return 0
    } else {
        return data
    }
}



const IndexPage: React.FC = () => {
    const [userData, setUserData] = useState<UserData | null>(null)
    const [scoreCookie, setScoreCookie] = useCookies(['score_cookie']);
    const [score, setScore] = useState<number>(check_undefined(scoreCookie.score_cookie));


  useEffect(() => {
    if (WebApp.initDataUnsafe.user) {
      setUserData(WebApp.initDataUnsafe.user as UserData)
    }
  }, [])
    return(
        <>
            {/*<CssBaseline />*/}
            <AppRoot>
            <List>
                {/*<TooltipSection /> нажимаю на кнопку - появилось сообщение */}
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Button size="l" stretched>Dashboard</Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                        <Button size="l" stretched>Тапалка</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Score score={score} setScore={setScore}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Clicker score={score} setScore={setScore}/>
                    </Grid>
                </Grid>
                <BannerSection />
                {/*<CellSection />*/}
                {/*<FormSection />*/}
                {/*<TimelineSection />*/}
                {/*<ModalSection />*/}
            </List>
          </AppRoot>
            {userData ? (
                <>
                  <h1 className="text-2xl font-bold mb-4">User Data</h1>
                  <ul>
                    <li>ID: {userData.id}</li>
                    <li>First Name: {userData.first_name}</li>
                    <li>Last Name: {userData.last_name || 'N/A'}</li>
                    <li>Username: {userData.username || 'N/A'}</li>
                    <li>Language Code: {userData.language_code}</li>
                    <li>Is Premium: {userData.is_premium ? 'Yes' : 'No'}</li>
                  </ul>
                </>
            ) : (
                <div>Loading...</div>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
            </Box>
        </>
    )
}

export default IndexPage;
