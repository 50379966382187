import React, {useState} from "react";
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import {IScoreProps} from "../../types/props";
import {isMobile} from 'react-device-detect';
import {useCookies} from "react-cookie";


// https://ru.legacy.reactjs.org/docs/events.html#mouse-events
const Clicker: React.FC<IScoreProps> = ({score, setScore}) => {
    // let img1 = "https://martika-home.ru/upload/iblock/ebd/tarelka_dlya_pervykh_blyud_220kh40_S153_salatovyy.jpg"
    // let img2 = "https://martika-home.ru/upload/iblock/ffa/tarelka_dlya_pervykh_blyud_220kh40_S153_fioletovyy.jpg"
    let img1 = "/img/button1.png"
    let img2 = "/img/button2.png"
    const [validImage, setValidImage] = useState<string>(img1);
    const [scoreCookie, setScoreCookie] = useCookies(['score_cookie']);

    const clickerClichDesktop = () => {
        if(isMobile == false){
            setValidImage(img2)
            setScoreCookie('score_cookie', score+1)
            setScore(score+1)
            setTimeout(
          () => {
                setValidImage(img1)
                },100
            );
        }

    }

    const clickerClick = () => {
        if(isMobile){
            setValidImage(img2)
            setScoreCookie('score_cookie', score+1)
            setScore(score+1)
        }
    }

    const clickerClickEnd = () => {
        setValidImage(img1)
    }

    var size = '90vw'
    if (validImage == img2){
        size = '88vw'
    }
    var size2 = '0vw'
    if (validImage == img2){
        size2 = '2vw'
    }

    return (
        <>
            <Box>
                <Grid container spacing={{ xs: 2, md: 3 }}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                          style={{textAlign: "center"}}
                    >
                        <Box m="auto">
                            <img
                                src={validImage}
                                alt="my image"
                                onClick={clickerClichDesktop}
                                onTouchStart={clickerClick}
                                onTouchEnd={clickerClickEnd}
                                style={{
                                    height: size,
                                    width: size,
                                    boxShadow: 'none',
                                    outline: 'none'
                                }}
                            />
                            <div style={{
                                    height: size2,
                                    width: size2,
                                    boxShadow: 'none',
                                    outline: 'none'
                                }}></div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Clicker;
